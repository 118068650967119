













import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LanguageSwitcher extends Vue {
  changeLocale(locale: string) {
    this.$root.$i18n.locale = locale;
  }
}
