


























import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { HierarchyNode } from '@/types/resources/HierarchyNode';

@Component
export default class HierarchyView extends Vue {
  private hierarchyNode: HierarchyNode | null = null;

  private filterQuery = '';

  async loadParentHierarchyNode() {
    this.hierarchyNode = this.$store.getters['hierarchy/getHierarchyNode'](this.$route.params.hierarchyNodeId);

    if (!this.hierarchyNode) {
      await this.$store.dispatch('hierarchy/loadHierarchyNodes');
      this.hierarchyNode = this.$store.getters['hierarchy/getHierarchyNode'](this.$route.params.hierarchyNodeId);
    }
  }

  mounted() {
    this.loadParentHierarchyNode();
  }

  @Watch('$route.params')
  changedNode() {
    this.loadParentHierarchyNode();
    this.filterQuery = '';
  }
}
