









import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SidebarUserPopover extends Vue {
  async logout() {
    this.$store.commit('auth/LOGOUT');
    await this.$router.push('/login');
  }
}
