












import Component from 'vue-class-component';
import Vue from 'vue';
import Sidebar from '@/components/Layout/Sidebar.vue';
import Toolbar from '@/components/Layout/Toolbar.vue';
import { ToolbarAction } from '@/types/shared/ToolbarAction';
import { Resource } from '@api-platform/api-doc-parser/lib';

@Component({
  components: { Sidebar, Toolbar },
})
export default class IndexPage extends Vue {
  public $refs!: {
    sidebar: Sidebar;
    contentArea: HTMLElement;
  };

  toggleSidebar() {
    this.$refs.sidebar.$el.classList.toggle('open');
    this.$refs.contentArea.classList.toggle('open');
  }

  navigate(path: string) {
    this.toggleSidebar();
    this.$router.push(path);
  }

  get toolbarActions() {
    const options: ToolbarAction[] = [];
    const resource = this.$store.state.api.resources.find(
      (r: Resource) => r.url.split('/').reverse()[0] === this.$route.params.resourceTitle,
    );
    if (resource) {
      const resourceParam = resource.url.split('/').reverse()[0];
      if (this.$options?.components && this.$options.components[`${resource.title}CreateForm`]) {
        options.push({
          name: this.$t('create.label') as string,
          icon: 'plus',
          link: `/${resourceParam}/create`,
        });
      }
    }

    return options;
  }

  async mounted() {
    await this.$store.dispatch('api/loadApiDocumentation');
  }
}
